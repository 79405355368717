import { render, staticRenderFns } from "./ModalUnpublishItem.vue?vue&type=template&id=4cb459e8&scoped=true"
import script from "./ModalUnpublishItem.vue?vue&type=script&lang=js"
export * from "./ModalUnpublishItem.vue?vue&type=script&lang=js"
import style0 from "./ModalUnpublishItem.vue?vue&type=style&index=0&id=4cb459e8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cb459e8",
  null
  
)

export default component.exports